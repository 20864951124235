import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Combobox } from "@headlessui/react";

import classNames from "../../../../util/classNames";
import { storeInLocal } from "../../../../util/storeInLocal";
import MdxFunctionCompiler from "../../../../util/mdxFunctionCompiler";
import SvgIcon from "../../../../Icons/SvgIcon";

const ComboBoxOption = memo(({ each, to }) => {
  let isJsx = false;
  isJsx = each && each.subject;

  const handelGotoSelectItem = (value) => {
    storeInLocal(value);
  };

  return (
    <Link to={to}>
      <Combobox.Option
        value={each}
        className={({ active }) =>
          classNames(
            "flex cursor-pointer select-none items-center px-4 py-2",
            active && "bg-indigo-600 text-white"
          )
        }
        onClick={() => handelGotoSelectItem(each)}
      >
        {({ active }) => (
          <>
            <SvgIcon iconName="duo-folder-closed" sizeName="lg" className={`${active ? `fill-white` : `fill-gray-400`}`} />
            <span className="ml-3 flex-auto truncate">
              {isJsx ? (
                <MdxFunctionCompiler jsxFunction={each.name} />
              ) : (
                <> {each.name}</>
              )}
            </span>
            <span>{each.subject}</span>
          </>
        )}
      </Combobox.Option>
    </Link>
  );
});

export default ComboBoxOption;
