import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const AddQuestionSection = lazy(() => import('../../components/authentication-and-user-management/admin-section/view/AddQuestionSection'));

function AdminPage(props) {
  return (
    <>
      <Meta title="Admin" />
      <Suspense fallback={<div>Loading...</div>}>
        <AddQuestionSection />
      </Suspense>
    </>
  );
}

export default requireAuth(AdminPage);
