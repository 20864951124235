const getIPAddress = async () => {
  try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        return data.ip;
    } catch (error) {
        return console.error(error);
    }
};

export default getIPAddress;
