import React, { useEffect, useRef, useState } from "react";

import { Link } from "../../../../util/router";
import { useAuth } from "../../../../util/auth";
import { getPricingPlan } from "../../../../util/db";
import PricingSkeleton from "./PricingSkeleton";
import { useAppContext } from "../../../../context/AppContext";
import SvgIcon from "../../../../Icons/SvgIcon";

function PricingSection(props) {
  const auth = useAuth();
  const [pricingList, setPricingList] = useState([]);
  const [isPricingLoading, setIsPricingLoading] = useState(true);
  const buttonActiveClass = useRef("ring-2 ring-indigo-500 bg-white");
  const buttonActiveClassInDark = useRef("ring-2 ring-indigo-500 bg-[#515354]");
  const { isDarkTheme } = useAppContext();

  const buttonList = ["Monthly", "Yearly", "One Time"];
  const [active, setActive] = useState("Yearly");

  const returnPriceValue = (eachPlan) => {
    switch (active) {
      case "Monthly":
        return eachPlan.monthlyPrice;
      case "Yearly":
        return eachPlan.yearlyPrice;
      case "One time":
        return eachPlan.oneTimePrice;
      default:
        break;
    }
  };

  const returnTimePeriod = () => {
    switch (active) {
      case "Monthly":
        return "/mo";
      case "Yearly":
        return "/yr";
      case "One time":
        return "/life time";
      default:
        break;
    }
  };

  useEffect(() => {
    getPricingPlan().then((response) => {
      if (response.status === "success") {
        setPricingList([...response.data]);
        setIsPricingLoading(false);
      }
    }).catch((error) => {
      setIsPricingLoading(false);
    })
  }, []);

  return (
    <section className="py-12">
      <div className="mx-auto max-w-7xl py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:align-center sm:flex sm:flex-col">
          <h1 className={`text-5xl font-bold tracking-tight sm:text-center ${isDarkTheme ? `text-white` : `text-gray-900`}`}>Pricing Plans</h1>
          <p className={`mt-5 text-xl sm:text-center ${isDarkTheme ? `text-slate-300` : `text-gray-500`}`}>
            Start building for free, then add a site plan to go live. Account plans unlock additional features.
          </p>
          <div className={`relative mt-6 flex self-center rounded-lg p-0.5 sm:mt-8 ${isDarkTheme ? `bg-[#333333]` : `bg-gray-100`}`}>
            {buttonList.map((buttonType, index) => (
              <button
              key={index}
              type="button"
              className={`relative w-1/2 whitespace-nowrap rounded-md py-2 text-sm font-medium focus:z-10 focus:outline-none sm:w-auto sm:px-8 border-gray-200  ${isDarkTheme ? `text-white ${active === buttonType ? buttonActiveClassInDark.current : "" }` : `text-gray-900 ${active === buttonType ? buttonActiveClass.current : "" }`} shadow-sm mr-1`}
              onClick={() => setActive(buttonType)}
            >
              {buttonType} Billing
            </button>
            ))}
          </div>
        </div>
        {isPricingLoading && <PricingSkeleton />}
        {!isPricingLoading && pricingList.length ? (
          <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
            {pricingList.map((plan, index) => (
              <div key={index} className={`divide-y rounded-lg  shadow-sm ${isDarkTheme ? `bg-[#333333]` : `bg-white`}`}>
                <div className="p-6">
                  <h2 className={`text-lg font-medium leading-6 ${isDarkTheme ? `text-white` : `text-gray-900`}`}>
                    {plan.planName}
                  </h2>
                  <p className="mt-8">
                    <span className={`text-4xl font-bold tracking-tight ${isDarkTheme ? `text-white` : `text-gray-900`}`}>
                     ${returnPriceValue(plan)}
                    </span>{" "}
                    <span className={`text-base font-medium ${isDarkTheme ? `text-slate-300` : `text-gray-500`}`}>
                      {returnTimePeriod()}
                    </span>
                  </p>
                  <Link
                    to={
                      auth.user
                        ? `/purchase/${plan.planName.toLowerCase()}`
                        : `/auth/signup?next=/purchase/${plan.planName.toLowerCase()}`
                    }
                    className={`mt-8 block w-full rounded-md border border-gray-800 py-2 text-center text-sm font-semibold text-white ${isDarkTheme ? `bg-[#515354] hover:bg-[#96989d]` : `bg-gray-800 hover:bg-gray-900`}`}
                  >
                    Buy {plan.planName}
                  </Link>
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className={`text-sm font-medium ${isDarkTheme ? `text-white` : `text-gray-900`}`}>
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {plan.feature.map((eachFeature, index) => (
                      <li className="flex items-center space-x-3" key={index}>
                        <SvgIcon iconName="duo-check" sizeName="lg" className="fill-green-500" />
                        <span className={`text-sm ${isDarkTheme ? `text-slate-300` : `text-gray-500`}`}>
                          {eachFeature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </section>
  );
}

export default PricingSection;
