import { useEffect } from "react";
import mockDocumentObj from "../../util/mockDocumentObj";

export const useSearchController = (getRecentSearch, floatingSearchBoxShown) => {
    useEffect(() => {
        const handelKeyDown = ((event) => {
            if (event.key === "k" && event.ctrlKey) {
                let selectedItemsFromSearch = JSON.parse(
                    localStorage.getItem("selectedItemsFromSearch")
                );
                getRecentSearch(selectedItemsFromSearch ? selectedItemsFromSearch.reverse() : [])
                event.preventDefault();
                floatingSearchBoxShown(true);
            }
        })
        mockDocumentObj().addEventListener("keydown", handelKeyDown);
        return () => mockDocumentObj().removeEventListener("keydown", handelKeyDown);
    }, [getRecentSearch, floatingSearchBoxShown]);

}