import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";

import useActiveNav from "../../../../hooks/authentication-and-user-information/useActiveNav";
import { Link } from "../../../../util/router";
import adminSubmenu from "../SubMenu/adminSubmenu";
import AdminMenuName from "./AdminMenuName";

const DropDownSubMenu = ({handelCloseAfterClick}) => {
  const [open, setOpen] = useState(false);
  const adminSubmenuList= adminSubmenu();
  const { getActiveClass } = useActiveNav();

  const handelOpen = () => {
    setOpen((previous) => !previous);
  };

  return (
    <div className="px-3 w-full">
      <div role="button" onClick={handelOpen}>
        <AdminMenuName isOpen={open} />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="top"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="top"
          leaveTo="opacity-0"
        >
          <div className="flex flex-col">
            {adminSubmenuList.map((each) => (
              <Link to={each.to} onClick={handelCloseAfterClick} role={"button"}  key={each.name} className={`py-2 text-xl font-semibold ${getActiveClass(each.to, true)}`}>
                {each.name}
              </Link>
            ))}

          </div>
        </Transition.Child>
      </Transition.Root>
    </div>
  );
};

export default DropDownSubMenu;
