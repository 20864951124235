import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const DeletedListSection = lazy(() => import('../../components/archive-and-deleted-data/deleted-list-section/view/DeletedListSection'));

function DeletedListPage(props) {
  return (
    <>
      <Meta title="Deleted list" />
      <Suspense fallback={<div>Loading...</div>}>
        <DeletedListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(DeletedListPage);