import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const ArchiveListSection = lazy(() => import('../../components/archive-and-deleted-data/archive-list-section/view/ArchiveListSection'));

function ArchivedListPage(props) {
  return (
    <>
      <Meta title="Archive list" />
      <Suspense fallback={<div>Loading...</div>}>
        <ArchiveListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(ArchivedListPage);