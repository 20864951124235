import { DeviceUUID } from "device-uuid";
import { useEffect, useState } from "react";
import getIPAddress from "./getIpAddress";

const useDeviceUId = () => {
  const [deviceInfo, setDeviceInfo] = useState({});

  useEffect(() => {
    const du = new DeviceUUID().parse();

    (async () => {
      const ipAddress = await getIPAddress();
      const duaList = [
        du.language,
        du.platform,
        du.os,
        du.cpuCores,
        du.isAuthoritative,
        du.silkAccelerated,
        du.isKindleFire,
        du.isDesktop,
        du.isMobile,
        du.isTablet,
        du.isWindows,
        du.isLinux,
        du.isLinux64,
        du.isMac,
        du.isiPad,
        du.isiPhone,
        du.isiPod,
        du.isSmartTV,
        du.pixelDepth,
        du.isTouchScreen,
        du.browser,
        du.geoIp,
        du.resolution,
        ipAddress,
      ];

      const uuid = du.hashMD5(duaList.join(":"));

      setDeviceInfo({
        deviceId: uuid,
        language: du.language,
        platform: du.platform,
        os: du.os,
        cpuCores: du.cpuCores,
        isAuthoritative: du.isAuthoritative,
        silkAccelerated: du.silkAccelerated,
        isKindleFire: du.isKindleFire,
        isDesktop: du.isDesktop,
        isMobile: du.isMobile,
        isTablet: du.isTablet,
        isWindows: du.isWindows,
        isLinux: du.isLinux,
        isLinux64: du.isLinux64,
        isMac: du.isMac,
        isiPad: du.isiPad,
        isiPhone: du.isiPhone,
        isiPod: du.isiPod,
        isSmartTV: du.isSmartTV,
        pixelDepth: du.pixelDepth,
        isTouchScreen: du.isTouchScreen,
        browser: du.browser,
        geoIp: du.geoIp,
        resolution: du.resolution,
        ipAddress: ipAddress,
      });
    })();
  }, []);

  return { deviceInfo };
};

export default useDeviceUId;
