import React from "react";

import { icons, size } from "./Icons";
import { useAppContext } from "../context/AppContext";

const SvgIcon = ({ iconName, sizeName, className="", color="" }) => {
  const { isDarkTheme } = useAppContext();
  const fill = isDarkTheme ? "#fff": "#1e3050";
  const IconComponent = icons[iconName];
  const iconSize = size[sizeName];
  return IconComponent ? (
    <IconComponent width={iconSize} height={iconSize} fill={!color && fill} className={`${color} ${className}`} />
  ) : null;
};

export default SvgIcon;
