import { useCallback } from "react";
import { useDispatch } from "react-redux";

import * as actionTypeSearch from "../../floating-search/redux/action";

const useClearAllStore = () => {
  const dispatch = useDispatch();

  const dispatchMySaga = useCallback(() => {
    dispatch(actionTypeSearch.getUserPermissions({}));
    dispatch(actionTypeSearch.getRecentSearch([]));
  }, [dispatch]);

  const handelClearAllStore = useCallback( async () => {
    dispatchMySaga();
  }, [dispatchMySaga]);

  return { handelClearAllStore };
};

export default useClearAllStore;
