import { useEffect, useState } from "react";

import randomColor from "../../../../constants/randomColor.json";
import SvgIcon from "../../../../Icons/SvgIcon";

const UserAvatar = ({ userName }) => {
  const [userProfileObj, setUserProfileObj] = useState({
    bgColor: "",
    profileName: "",
  });

  useEffect(() => {
    if (userName) {
      const userNameFirstLetter = userName.charAt(0);
      const profileBackgroundColor =
        randomColor[userNameFirstLetter.toLowerCase()];

      setUserProfileObj({
        profileName: userNameFirstLetter.toUpperCase(),
        bgColor: profileBackgroundColor,
      });
    }
  }, [userName]);

  return (
    <>
      {userName ? (
        <div
          className={`h-8 w-8 rounded-full text-white flex items-center justify-center`}
          style={{
            backgroundColor: userProfileObj.bgColor,
          }}
        >
          {userProfileObj.profileName}
        </div>
      ) : (
        <SvgIcon iconName="duo-circle-user" sizeName="2xl" />
      )}
    </>
  );
};

export default UserAvatar;
