import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";
import { requestApi, requestPdfApi } from "./requestApi";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}


// Subscribe to all static contents
export function useContents() {
  return useQuery(
    ["contents"],
    createQuery(() =>
      query(
        collection(db, "contents")
      )
    )
  );
}


// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}


// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

// set and update store for all data that return from firebase.
export const dataPromise=(data)=> { 
  return Promise.resolve(data);
}

// update questionsList
export const insertQuestionInDocument = async (subjectName, questionObj, questionId) => {
  return requestApi("/.netlify/functions/qa_update-question-by-code", "POST", {subjectName: subjectName, questionObj: questionObj, questionId: questionId});
};

// Get qsn List by subject
export const getQuestionsListBySubjectName = async (subjectName) => {
  return requestApi("/.netlify/functions/qa_question-list-by-subject-name", "POST",{ subjectName: subjectName});
};

// qa_get-answer-by-qsn-code-and-level and get ans
export const updateQuestionKeyValue = async (data) => {
  return requestApi("/.netlify/functions/qa_get-answer-by-qsn-code-and-level", "POST", data);
};

// qa_all-subjects-questions-list
export const getAllSubJectQuestions = async () => {
  return requestApi("/.netlify/functions/qa_all-subjects-questions-list", "POST")
};

// qa_clone-questions-list-for-new-user-background
export const onBoardQsnJob  = async () => {
  return requestApi("/.netlify/functions/qa_clone-questions-list-for-new-user-background","POST");
};

// issue_claim-generator
export const customClaimsGenerator  = async (data) => {
  return requestApi("/.netlify/functions/issue_claim-generator", "POST", data)
};

// other_get-permission
export const getPermissions  = async (data={userEmail:""}) => {
  return requestApi("/.netlify/functions/other_get-permission", "POST", data)
};

// qa_get-question-answer-by-code
export const getQsnAnswerByCode  = async (data) => {
  return requestApi("/.netlify/functions/qa_get-question-answer-by-code", "POST", data)
};

// qa_drag-and-drop-questions-list
export const setDragAndDropQuestionList  = async (data) => {
  return requestApi("/.netlify/functions/qa_drag-and-drop-questions-list", "POST", data)
};

// content_html-to-pdf-convertor
export const questionAnsExportToPdf  = async (data) => {
  return requestPdfApi("/.netlify/functions/content_html-to-pdf-convertor", "POST", data);
};

// log in info
export const setLogInInfo  = async (data) => {
  return requestApi("/.netlify/functions/account_log-in-info", "POST", data)
};

// account_log-out-from-all-devices
export const logOutFromAllDevices  = async () => {
  return requestApi("/.netlify/functions/account_log-out-from-all-devices", "PUT")
};

// qa_original-ans-by-code
export const originalAnsByCode  = async (data) => {
  return requestApi("/.netlify/functions/qa_original-ans-by-code", "POST", data)
};

// qa_question-answer-view-status
export const markAsReadUnread  = async (data) => {
  return requestApi("/.netlify/functions/qa_question-answer-view-status", "PUT", data)
};

// account_make-all-unread
export const makeAllUnread  = async (data) => {
  return requestApi("/.netlify/functions/account_make-all-unread", "PUT", data)
};

// qa_archive-question
export const archiveQuestion  = async (data) => {
  return requestApi("/.netlify/functions/qa_archive-question", "PATCH", data)
};

// qa_archive-list-by-subject-name
export const getArchiveListBySubjectName  = async (data) => {
  return requestApi("/.netlify/functions/qa_archive-list-by-subject-name", "POST", data)
};

// qa_delete
export const deleteQuestion  = async (data) => {
  return requestApi("/.netlify/functions/qa_delete", "PATCH", data)
};

// content_faq
export const getFaqList  = async () => {
  return requestApi("/.netlify/functions/content_faq", "GET")
};

// qa_unarchive-all-by-subject-name
export const makeAllUnarchive  = async (data) => {
  return requestApi("/.netlify/functions/qa_unarchive-all-by-subject-name", "PUT", data)
};

// qa_delete-all-by-subject-name
export const deleteAllQuestions  = async (data) => {
  return requestApi("/.netlify/functions/qa_delete-all-by-subject-name", "PATCH", data)
};

// content_terms
export const getTerms  = async () => { 
  return requestApi("/.netlify/functions/content_terms", "GET")
}

// qa_restore-all-by-subject-name
export const makeAllRestore  = async (data) => {
  return requestApi("/.netlify/functions/qa_restore-all-by-subject-name", "PATCH", data)
};

// qa_deleted-list-by-subject-name
export const getDeleteListBySubjectName  = async (data) => {
  return requestApi("/.netlify/functions/qa_deleted-list-by-subject-name", "POST", data)
};

// account_users-list
export const getUsersList  = async ({lastDocument= "", pageSize=20, searchQuery = ""}) => {
  return requestApi(`/.netlify/functions/account_users-list?pageSize=${pageSize}${lastDocument?`&lastDocument=${lastDocument}`:''}${searchQuery?`&searchQuery=${searchQuery}`:''}`, "GET")
};

// analytic_api-logs-list-by-user-email
export const getApiLogsListByUserEmail  = async (data) => {
  return requestApi("/.netlify/functions/analytic_api-logs-list-by-user-email", "POST", data)
};

// qa_add-to-favorite
export const addToFav = async (data) => {
  return requestApi(`/.netlify/functions/qa_add-to-favorite`, "PATCH", data)
};

// qa_recent-view-list
export const recentViewQuestionsList  = async (page=1, limit=5) => {
  return requestApi(`/.netlify/functions/qa_recent-view-list?page=${page}&limit=${limit}`, "GET")
};

// qa_favorite-questions-list
export const favoriteQuestionsList  = async (page=1, limit=5) => {
  return requestApi(`/.netlify/functions/qa_favorite-questions-list?page=${page}&limit=${limit}`, "GET")
};

// analytic_hours-spent
export const hoursSpentInQuestionsListPage  = async (data) => {
  return requestApi("/.netlify/functions/analytic_hours-spent", "POST", data)
};

// analytic_time-spent-week
export const getDayChartData  = async (previousDays=14) => {
  return requestApi(`/.netlify/functions/analytic_time-spent-week?previousDays=${previousDays}`, "GET")
};

// analytic_time-spent-week-compare-data
export const getDayChartDataForWeekCompare  = async (previousDays=14, weekStartDay=0) => {
  const queryString = weekStartDay ? `&weekStartDay=${weekStartDay}` : '';
  const url = `/.netlify/functions/analytic_time-spent-week-compare-data?previousDays=${previousDays}${queryString}`;
  return requestApi(url, 'GET');
};

// qa_get-subjects-types
export const getAllSubjectTypes  = async () => {
  return requestApi(`/.netlify/functions/qa_get-subjects-types`, "GET")
};

// quiz_get-subject-type
export const getAllQuizSubjectTypes  = async () => {
  return requestApi(`/.netlify/functions/quiz_get-subject-type`, "GET")
};

// billing_pricing-plan
export const getPricingPlan  = async () => {
  return requestApi(`/.netlify/functions/billing_pricing-plan`, "GET")
};

// analytic_clear-cache-background
export const cleanCache  = async (cacheKeyName="") => {
  return requestApi(`/.netlify/functions/analytic_clear-cache-background?cacheKeyName=${cacheKeyName}`, "PATCH")
};

// analytic_clear-each-user-cache-background
export const cleanEachUserCache  = async (userId="") => {
  return requestApi(`/.netlify/functions/analytic_clear-each-user-cache-background?userId=${userId}`, "PATCH")
};

// qa_questions-list-and-answers-by-subject-name
export const getQuestionsListAndAnsBySubjectName = async (data={subjectName:""}) => {
  return requestApi(`/.netlify/functions/qa_questions-list-and-answers-by-subject-name`, "POST", data)
};

// quiz_get-details
export const getQuizSubjectDetails = async (data={subjectName:""}) => {
  return requestApi(`/.netlify/functions/quiz_get-details`, "POST", data)
};

// quiz_set-mcq-subject-and-details
export const setQuizDetails = async (data={subjectName:"", setQuizDetails:{}}) => {
  return requestApi(`/.netlify/functions/quiz_set-mcq-subject-and-details`, "POST", data)
};

// quiz_get-mcq
export const getMcqListBySubjectName = async (data={subjectName:""}) => {
  return requestApi(`/.netlify/functions/quiz_get-mcq`, "POST", data)
};

// quiz_get-mcq-by-question-id
export const getMcqByQuestionId = async (data={subjectName:"", questionNumber: ""}) => {
  return requestApi(`/.netlify/functions/quiz_get-mcq-by-question-id`, "POST", data)
};

// quiz_add-mcq
export const addQuizMcq = async (data={subjectName:"", questionNumber:"", mcqObject:{}}) => {
  return requestApi(`/.netlify/functions/quiz_add-mcq`, "POST", data)
};

// quiz_add-mcq-list
export const addQuizMCQList = async (data={subjectName:"", mcqList:[]}) => {
  return requestApi(`/.netlify/functions/quiz_add-mcq-list`, "POST", data)
};

// quiz_subject-all-details-and-quizzes-list
export const getSubjectAllDetailsAndQuizzesList = async (data={subjectName:""}) => {
  return requestApi(`/.netlify/functions/quiz_subject-all-details-and-quizzes-list`, "POST", data)
};

// analytic_set-max-time-to-finish-page-globally
export const setMaxTimeToFinishQuizPageGlobally = async (data={maxTimeToFinishPage: "", subjectName: ""}) => {
  return requestApi(`/.netlify/functions/analytic_set-max-time-to-finish-page-globally`, "POST", data)
};

// quiz_clone-quizzes-collection-doc-background
export const onBoardQuizzesJob  = async () => {
  return requestApi("/.netlify/functions/quiz_clone-quizzes-collection-doc-background","POST");
};

// analytic_get-max-time-to-finish-page
export const getMaxTimeToFinishThisPage  = async () => {
  return requestApi(`/.netlify/functions/analytic_get-max-time-to-finish-page`, "GET")
};

// quiz_delete
export const deleteQuiz = async (data={subjectName:"", isDeleted: false, quizId: ""}) => {
  return requestApi("/.netlify/functions/quiz_delete", "PATCH", data)
};

// quiz_deleted-list-by-subject-name
export const getQuizzesDeletedListBySubjectName = async (data={subjectName:""}) => {
  return requestApi(`/.netlify/functions/quiz_deleted-list-by-subject-name`, "POST", data)
};

//quiz_restore-all-deleted-quizzes
export const restoreAllQuizzesBySubjectName = async (data={subjectName:""}) => {
  return requestApi("/.netlify/functions/quiz_restore-all-deleted-quizzes", "PATCH", data)
};

//support
export const postAQuestionForSupport = async (data={questionName:"", description: "", category: ""}) => {
  return requestApi(`/.netlify/functions/support`, "POST", data)
};

// issue_list
export const getIssueList  = async (category="", status="") => {
  let queryString = "";
  if (category) {
    queryString += `?category=${category}`;
  }
  if (status) {
    queryString += `${category ? '&' : '?'}status=${status}`;
  }
  return requestApi(`/.netlify/functions/issue_list${queryString}`, "GET")
};

// issue_assign-list
export const getAssignIssueList  = async (category="", status="") => {
  let queryString = "";
  if (category) {
    queryString += `?category=${category}`;
  }
  if (status) {
    queryString += `${category ? '&' : '?'}status=${status}`;
  }
  return requestApi(`/.netlify/functions/issue_assign-list${queryString}`, "GET")
};

// issue_get-by-id
export const getIssueById  = async (issueId="") => {
  const queryString = issueId ? `?issueId=${issueId}` : '';
  return requestApi(`/.netlify/functions/issue_get-by-id${queryString}`, "GET")
};

// support_manage-each-data
export const manageEachSupportData  = async (data={questionName: "", description: "", issueId: "", status: "", category: "" , priority: "", assignTo: ""}) => {
  return requestApi(`/.netlify/functions/support_manage-each-data`, "POST", data)
};

// issue_update-status-by-id
export const updateIssueStatus  = async (data={issueId: "", status: ""}) => {
  return requestApi(`/.netlify/functions/issue_update-status-by-id`, "POST", data)
};

// account_get-agent-list
export const getAgentList  = async () => {
  return requestApi(`/.netlify/functions/account_get-agent-list`, "GET")
};

// account_get-agent-by-id
export const getAgentById  = async (agentId="") => {
  const queryString = agentId ? `?agentId=${agentId}` : '';
  return requestApi(`/.netlify/functions/account_get-agent-by-id${queryString}`, "GET")
};

// account_add-expertise-in-agent
export const addExpertise  = async (data={expertise:"", agentId: ""}) => {
  return requestApi(`/.netlify/functions/account_add-expertise-in-agent`, "POST", data)
};

// account_remove-expertise-in-agent
export const removeExpertise  = async (data={expertise:"", agentId: ""}) => {
  return requestApi(`/.netlify/functions/account_remove-expertise-in-agent`, "POST", data)
};

// issue_discuss
export const discussOnIssue  = async (data={issueId:"", content: ""}) => {
  return requestApi(`/.netlify/functions/issue_discuss`, "POST", data)
};

// issue_discuss-messages
export const getDiscussById  = async (issueId="") => {
  const queryString = issueId ? `?issueId=${issueId}` : '';
  return requestApi(`/.netlify/functions/issue_discuss-messages${queryString}`, "GET")
};

// issue_assign-to-agent-by-id
export const assignToAgentById  = async (data={issueId: "", agentEmail: ""}) => {
  return requestApi(`/.netlify/functions/issue_assign-to-agent-by-id`, "POST", data)
};

// content_demo-add-mdx
export const demoAddMdxValue  = async (data={docName: "", value: ""}) => {
  return requestApi(`/.netlify/functions/content_demo-add-mdx`, "POST", data)
};

// content_privacy-policy
export const getPrivacyPolicy  = async () => { 
  return requestApi("/.netlify/functions/content_privacy-policy", "GET")
}

// billing_refund-policy
export const getRefundPolicy  = async () => { 
  return requestApi("/.netlify/functions/billing_refund-policy", "GET")
}

// account_new-user-permission-background
export const setNewUserPermission  = async () => { 
  return requestApi("/.netlify/functions/account_new-user-permission-background", "GET")
}

// qa_add-subject
export const addSubjectQuestionAnswer  = async (data={subjectType: "", subjectName: ""}) => {
  return requestApi(`/.netlify/functions/qa_add-subject`, "POST", data)
};

// qa_delete-subject
export const deleteSubjectQuestionAnswer  = async (data={subjectType: "", subjectName: ""}) => {
  return requestApi(`/.netlify/functions/qa_delete-subject`, "POST", data)
};

// quiz_add-subject
export const addSubjectQuiz  = async (data={subjectType: "", subjectName: ""}) => {
  return requestApi(`/.netlify/functions/quiz_add-subject`, "POST", data)
};

// quiz_delete-subject
export const deleteSubjectQuiz  = async (data={subjectType: "", subjectName: ""}) => {
  return requestApi(`/.netlify/functions/quiz_delete-subject`, "POST", data)
};