import { useAppContext } from "../../../../context/AppContext";
import classNames from "../../../../util/classNames";

const Select = ({
  label,
  options = [], marginTop = `mt-2`, placeHolder = "Select one", ...props }) => {
  const { isDarkTheme } = useAppContext();

  return (
    <>
      <label className={classNames(
        `text-sm font-medium leading-6`
      )}
      >
        {label}
      </label>
      <div className={`${marginTop} w-full`}>
        <select
          {...props}
          className={classNames(
            isDarkTheme ? `bg-[#333333] border-[#777777]` : `border-gray-300`,
            `block w-full rounded-md border p-2 px-4 outline-none focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`
          )}
        >
          <option value={""}>{placeHolder}</option>
          {options.map((each, index) => (
            <option key={each.name} value={each.value}>
              {each.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default Select;
