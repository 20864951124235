import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import NotFoundImage from "../../assets/404.svg";

function NotFoundPage(props) {
  return (
    <div>
      <Meta title="404" />
      <div className="flex justify-center items-center mt-8">
        <img src={NotFoundImage} alt="404 " />
      </div>
    </div>
  );
}

export default NotFoundPage;
