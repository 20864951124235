import { useAppContext } from "../../../../context/AppContext";
import classNames from "../../../../util/classNames";

const BottomHelperDoc = ({ rawQuery }) => {
  const { isDarkTheme } = useAppContext();

  return (
    <div className={`flex flex-wrap items-center py-2.5 px-4 text-xs ${isDarkTheme ? `text-white bg-[#333333]` : `text-gray-700 bg-gray-50`}`}>
      Type{" "}
      <kbd
        className={classNames(
          "mx-1 flex h-5 w-5 items-center justify-center rounded border font-semibold sm:mx-2",
          rawQuery.startsWith("#")
            ? "border-indigo-600 text-indigo-600"
            : `border-gray-400 ${isDarkTheme ? `text-white` : `text-gray-900`}`,
            isDarkTheme ? `bg-[#333333]` : `bg-white`
        )}
      >
        {"="}
      </kbd>{" "}
      <span className="sm:hidden">for Quiz, </span>
      <span className="hidden sm:inline">to access Quiz, </span>
      <>
        <kbd
          className={classNames(
            "mx-1 flex h-5 w-5 items-center justify-center rounded border font-semibold sm:mx-2",
            rawQuery.startsWith("#")
              ? "border-indigo-600 text-indigo-600"
              : `border-gray-400 ${isDarkTheme ? `text-white` : `text-gray-900`}`,
            isDarkTheme ? `bg-[#333333]` : `bg-white`
          )}
        >
          #
        </kbd>{" "}
        <span className="sm:hidden">for Subjects,</span>
        <span className="hidden sm:inline">to access Subjects,</span>

        <kbd
          className={classNames(
            "mx-1 flex h-5 w-5 items-center justify-center rounded border font-semibold sm:mx-2",
            rawQuery.startsWith(">")
              ? "border-indigo-600 text-indigo-600"
              : `border-gray-400 ${isDarkTheme ? `text-white` : `text-gray-900`}`,
            isDarkTheme ? `bg-[#333333]` : `bg-white`
          )}
        >
          &gt;
        </kbd>{" "}
        for Questions,
      </>
      <span className="ml-0.5">{" "}and{" "}</span>
      <kbd
        className={classNames(
          "mx-1 flex h-5 w-5 items-center justify-center rounded border font-semibold sm:mx-2",
          rawQuery === "?"
            ? "border-indigo-600 text-indigo-600"
            : `border-gray-400 ${isDarkTheme ? `text-white` : `text-gray-900`}`,
            isDarkTheme ? `bg-[#333333]` : `bg-white`
        )}
      >
        ?
      </kbd>{" "}
      for help.
    </div>
  );
};

export default BottomHelperDoc;
