import { useCallback, useEffect, useRef } from "react";
import useSearchParams from "./useSearchParams";
import Idle from "../../util/idle";
import { hoursSpentInQuestionsListPage } from "../../util/db";
import { useParams } from "react-router-dom";

const useScreenUses = () => {
  const qId = useSearchParams("qId");
  const { subject } = useParams();
  const intervalValue = useRef(null);
  const isOtherPage = useRef(false);
// TODO: code clean
  const handelClearInterval = useCallback(
    (pathname) => {
      const rootPathName = pathname.slice(0, 10);
      if (rootPathName !== "/subjects/") {
        isOtherPage.current = true;

        if (intervalValue.current) {
          clearInterval(intervalValue.current);
        }
      }
    },
    [intervalValue]
  );

  const handelScreenTiming = useCallback(() => {
    if (subject) {
      hoursSpentInQuestionsListPage({ qsnCode: qId, subjectName: subject })
        .then((response) => {
          console.error(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [qId, subject]);

  const awayCallback = function () {
    if (intervalValue.current) {
      clearInterval(intervalValue.current);
      console.log(intervalValue.current, "end when away");
    }
  };

  const awayBackCallback = function () {
    if (intervalValue.current && !isOtherPage.current) {
      clearInterval(intervalValue.current);
    }
    intervalValue.current = setInterval(() => {
      if (subject) {
        console.log(intervalValue.current, "start when back on mouse");
        handelScreenTiming();
      }
    }, 60000);
  };

  const onVisibleCallback = function () {
    if (intervalValue.current && !isOtherPage.current) {
      clearInterval(intervalValue.current);
    }
    intervalValue.current = setInterval(() => {
      if (subject) {
        console.log(intervalValue.current, "start when back to tab");
        handelScreenTiming();
      }
    }, 60000);
  };

  const onHiddenCallback = function () {
    if (intervalValue.current) {
      clearInterval(intervalValue.current);
      console.log(intervalValue.current, "end when leave");
    }
  };
  useEffect(() => {
    if (subject) {
      new Idle({
        onHidden: onHiddenCallback,
        onVisible: onVisibleCallback,
        onAway: awayCallback,
        onAwayBack: awayBackCallback,
        awayTimeout: 300000, //away with 5 minute of inactivity
      }).start();

      clearInterval(intervalValue.current);

      intervalValue.current = setInterval(() => {
       let kk = window.location.pathname
       const rootUrl = kk.slice(0, 10);
        if (intervalValue.current && rootUrl !== "/subjects/") {
          clearInterval(intervalValue.current);
          return;
        } else {
          console.log(intervalValue.current, "start effect");
          handelScreenTiming();
        }
      }, 60000);
    }
    // eslint-disable-next-line
  }, [subject]);

  return { handelClearInterval };
};
export default useScreenUses;
