import { useEffect } from "react";
import useTostMessage from "./useTostMessage";

const useInternetConnectivityChecker = () => {
  const { handelTostMessage } = useTostMessage();

  useEffect(() => {
    const handelKeyDown = () => {
        handelTostMessage(true, "success", "Back online");
    };
    window.addEventListener("online", handelKeyDown);
    return () => window.removeEventListener("online", handelKeyDown);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handelKeyDown = () => {
        handelTostMessage(true, "error", "No connection");
    };
    window.addEventListener("offline", handelKeyDown);
    return () => window.removeEventListener("offline", handelKeyDown);
    // eslint-disable-next-line
  }, []);

};

export default useInternetConnectivityChecker;
