import React from "react";
import { useNavigate } from "react-router";

import { Link } from "../../../../util/router";
import { useAuth } from "../../../../util/auth";
import adminSubmenu from "../SubMenu/adminSubmenu";
import profileSubMenu from "../SubMenu/profileSubMenu";
import useActiveNav from "../../../../hooks/authentication-and-user-information/useActiveNav";
import { useLogInInfo } from "../../../../hooks/authentication-and-user-information/useLogInInfo";
import useAuthorize from "../../../../hooks/authentication-and-user-information/useAuthorize";
import DropDownMenu from "../../re-usable-components/drop-down-menu/DropDownMenu";
import UserAvatar from "../components/UserAvatar";
import AdminMenuName from "../components/AdminMenuName";
import SideSheetNav from "../components/SideSheetNav";
import useClearAllStore from "../hooks/useClearAllStore";
import { useAppContext } from "../../../../context/AppContext";
import ToggleTheme from "../../toggle-theme/view/ToggleTheme";

function Navbar(props) {
  const auth = useAuth();
  const navigate = useNavigate();
  const { getActiveClass } = useActiveNav();
  const { handelLoginInfo } = useLogInInfo();
  const { isAuthorize } = useAuthorize();
  const { handelClearAllStore } = useClearAllStore();
  const { isDarkTheme } = useAppContext();

  const haveAdvancePlan = isAuthorize(["isAdvancePlan"]);
  
  const haveBasicPlan = isAuthorize(["isBasicPlan"]);

  const havePermissionForSupperAdmin = isAuthorize(["isSuperAdmin"]);

  const isAgent = isAuthorize(["isAgent"]);

  const handelClick = () => {
    navigate("/");
  }

  return (
    <>
    <div as="nav" className={isDarkTheme ? `bg-[#333333]` : `bg-white`}>
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-[73px] justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <SideSheetNav />
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start" onClick={handelClick}>
                <div className="flex cursor-pointer flex-shrink-0 items-center">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt="Your Company"
                  />
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center">

                  <ToggleTheme />

                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {(!auth.user || !auth.user.stripeSubscriptionId) && (
                    <Link to="/pricing" className={`ml-5 font-semibold ${getActiveClass("/pricing")}`}>
                      Pricing
                    </Link>
                  )}
                  {auth.user && (
                    <>
                      <Link to="/subjects" className={`ml-5 font-semibold ${getActiveClass("/subjects")}`}>
                        Subjects
                      </Link>

                      <Link to="/quiz-subjects" className={`ml-5 font-semibold ${getActiveClass("/quiz-subjects")}`}>
                        Quiz Subjects
                      </Link>

                      <Link to="/dashboard" className={`ml-5 font-semibold ${getActiveClass("/dashboard")}`}>
                        Dashboard
                      </Link>

                      <Link to="/quizzes-list" className={`ml-5 font-semibold ${getActiveClass("/quizzes-list")}`}>
                        Quizzes List
                      </Link>

                      {isAgent && <Link to="/assign-issue-list" className={`ml-5 font-semibold ${getActiveClass("/assign-issue-list")}`}>
                        Issue list
                      </Link>}

                      {!havePermissionForSupperAdmin && haveAdvancePlan && <Link to="/add-question" className={`ml-5 font-semibold ${getActiveClass("/add-question")}`}>
                        Add question
                      </Link>}
                      {havePermissionForSupperAdmin &&
                        <DropDownMenu menuName={<AdminMenuName />} menuList={adminSubmenu()} activeClass={getActiveClass("/admin")} mlClass="ml-0" />
                      }
                    </>
                  )}
                  {!auth.user && (
                    <Link to="/auth/signin" className={`ml-5 font-semibold ${getActiveClass("/signin")}`}>
                      Sign In
                    </Link>
                  )}
                </div>
                {/* Profile dropdown */}
                {auth.user && <DropDownMenu menuName={<UserAvatar userName={auth.user.firstName} />} menuList={profileSubMenu(auth, handelLoginInfo, handelClearAllStore, haveBasicPlan, haveAdvancePlan)} activeClass={getActiveClass("/settings")} />}
              </div>
            </div>
          </div>
        </>
    </div>
    </>
  );
}

export default Navbar;
