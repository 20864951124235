const adminSubmenu = () => {

  const menuList = [
    { name: "Add Question", to: `/admin/add-question` },
    { name: "Manage Claims", to: `/admin/manage-claims` },
    { name: "Manage Support", to: `/admin/manage-support` },
    { name: "Issue list", to: `/admin/issue-list` },
    { name: "Agent list", to: `/admin/agent-list` },
    { name: "Users List", to: `/admin/users-list` },
    { name: "API Logs", to: `/admin/api-logs` },
  ];

  return menuList;
};

export default adminSubmenu;
