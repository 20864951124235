import React from "react";

import LoadingAuthImg from "../../../../assets/loadingAuth.svg";

function PageLoader(props) {
  return (
    <div>
      {!props.children && (
        <>
          <div className="flex justify-center items-center mt-8">
            <img src={LoadingAuthImg} alt="404 " />
          </div>
        </>
      )}
      {props.children && <>{props.children}</>}
    </div>
  );
}

export default PageLoader;
