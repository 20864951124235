import React, { Fragment, useEffect, useState } from "react";
import { run } from "@mdx-js/mdx";
import * as runtime from "react/jsx-runtime";

export default function MdxFunctionCompiler({ jsxFunction }) {
  const [mdxModule, setMdxModule] = useState();
  const Content = mdxModule ? mdxModule.default : Fragment;

  useEffect(() => {
    if (jsxFunction) {
      (async () => {
        setMdxModule(await run(jsxFunction, runtime));
      })()
    }
  }, [jsxFunction]);

  return <Content />;
}
