import { GET_QUIZ_SUBJECT_TYPES, GET_SUBJECT_TYPES } from "./actionTypes";

export const getSubjectTypes = (data) => {
  return {
    type: GET_SUBJECT_TYPES,
    payload: data
  };
};

export const getQuizSubjectTypes = (data) => {
  return {
    type: GET_QUIZ_SUBJECT_TYPES,
    payload: data
  };
};
