import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useAddQuery = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  
  const addQuery = useCallback((pathname, key, value) => {
    let params = new URLSearchParams(search);
    params.set(key, value);
    navigate({
      pathname: pathname,
      search: params.toString(),
    });
  }, [search, navigate]);

  return { addQuery };

};
export default useAddQuery;
