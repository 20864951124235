import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "./../../util/auth";
import { useAppContext } from "../../context/AppContext";

const SubjectSection = lazy(() => import("../../components/quiz-management/subject-section/view/SubjectSection"));
const SubjectSectionMobile = lazy(() => import("../../components/quiz-management/subject-section-mobile/view/SubjectSectionsMobile"));


const SubjectPage = () => {

const { isMobile } = useAppContext();

  return (
    <>
      <Meta title="Subject" />
      <Suspense fallback={<div>Loading...</div>}>
        {isMobile ? <SubjectSectionMobile /> : <SubjectSection />}
      </Suspense>
    </>
  );
};

export default requireAuth(SubjectPage);
