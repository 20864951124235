import React from "react";

const PricingSkeleton = () => {
  let priceSkeleton = Array(3).fill("");

  return (
    <div className="animate-pulse">
      <div className="lg:flex lg:items-center mt-14 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
        {priceSkeleton.map((e, i) => (
          <div
            className="w-96 h-96 bg-slate-300 rounded flex justify-center m-auto"
            key={i + e + "price"}
          >
            <div className="flex-1">
              <div className="w-80 h-8 mt-24 mx-8 bg-gray-400 rounded"></div>
              <div className="h-px mt-4 bg-gray-400 rounded"></div>
              <div className="w-80 h-3 mt-12 mx-8 bg-gray-400 rounded"></div>
              <div className="w-80 h-3 mt-4 mx-8 bg-gray-400 rounded"></div>
              <div className="w-80 h-3 mt-4 mx-8 bg-gray-400 rounded"></div>
              <div className="w-80 h-3 mt-4 mx-8 bg-gray-400 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingSkeleton;
