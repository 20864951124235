import { ReactComponent as DuoAncor } from "./svg/duotone/anchor.svg";
import { ReactComponent as DuoGuitar } from "./svg/duotone/amp-guitar.svg";
import { ReactComponent as DuoArrowLeft } from "./svg/duotone/arrow-left.svg";
import { ReactComponent as DuoPlus } from "./svg/duotone/plus.svg";
import { ReactComponent as DuoMinus } from "./svg/duotone/minus.svg";
import { ReactComponent as DuoMagnifyingGlass } from "./svg/duotone/magnifying-glass.svg";
import { ReactComponent as DuoArrowUpWideShort } from "./svg/duotone/arrow-up-wide-short.svg";
import { ReactComponent as DuoArrowDownShortWide } from "./svg/duotone/arrow-down-short-wide.svg";
import { ReactComponent as DuoSpinnerThird } from "./svg/duotone/spinner-third.svg";
import { ReactComponent as DuoTrashXMark } from "./svg/duotone/trash-xmark.svg";
import { ReactComponent as DuoTrash } from "./svg/duotone/trash.svg";
import { ReactComponent as DuoSort } from "./svg/duotone/sort.svg";
import { ReactComponent as DuoEye } from "./svg/duotone/eye.svg";
import { ReactComponent as DuoEyeSlash } from "./svg/duotone/eye-slash.svg";
import { ReactComponent as DuoTrashUndo } from "./svg/duotone/trash-undo.svg";
import { ReactComponent as DuoArrowDown } from "./svg/duotone/arrow-down.svg";
import { ReactComponent as DuoSpinner } from "./svg/duotone/spinner.svg";
import { ReactComponent as DuoPaperPlane } from "./svg/duotone/paper-plane.svg";
import { ReactComponent as DuoXMark } from "./svg/duotone/xmark.svg";
import { ReactComponent as DuoBadgeCheck } from "./svg/duotone/badge-check.svg";
import { ReactComponent as SolidAngleUp } from "./svg/solid/angle-up.svg";
import { ReactComponent as SolidAngleDown } from "./svg/solid/angle-down.svg";
import { ReactComponent as DuoEllipsisVertical } from "./svg/duotone/ellipsis-vertical.svg";
import { ReactComponent as DuoCheckDouble } from "./svg/duotone/check-double.svg";
import { ReactComponent as DuoFilePdf } from "./svg/duotone/file-pdf.svg";
import { ReactComponent as DuoHeart } from "./svg/duotone/heart.svg";
import { ReactComponent as RegularHeart } from "./svg/regular/heart.svg";
import { ReactComponent as DuoSquareArrowLeft } from "./svg/duotone/square-arrow-left.svg";
import { ReactComponent as DuoCheck } from "./svg/duotone/check.svg";
import { ReactComponent as DuoFolderClosed } from "./svg/duotone/folder-closed.svg";
import { ReactComponent as DuoTriangleExclamation } from "./svg/duotone/triangle-exclamation.svg";
import { ReactComponent as DuoLifeRing } from "./svg/duotone/life-ring.svg";
import { ReactComponent as DuoBars } from "./svg/duotone/bars.svg";
import { ReactComponent as DuoCircleUser} from "./svg/duotone/circle-user.svg";
import { ReactComponent as DuoCircleCheck} from "./svg/duotone/circle-check.svg";
import { ReactComponent as ChevronsUp} from "./svg/duotone/chevrons-up.svg";
import { ReactComponent as BoxArchive} from "./svg/duotone/box-archive.svg";
import { ReactComponent as Pen} from "./svg/duotone/pen.svg";

export const icons = {
  "duo-ancor": DuoAncor,
  "duo-guitar": DuoGuitar,
  "duo-arrow-left": DuoArrowLeft,
  "duo-plus": DuoPlus,
  "duo-minus": DuoMinus,
  "duo-magnifying-glass": DuoMagnifyingGlass,
  "duo-arrow-up-wide-short": DuoArrowUpWideShort,
  "duo-arrow-down-short-wide": DuoArrowDownShortWide,
  "duo-spinner-third": DuoSpinnerThird,
  "duo-trash-x-mark": DuoTrashXMark,
  "duo-trash": DuoTrash,
  "duo-sort": DuoSort,
  "duo-eye": DuoEye,
  "duo-eye-slash": DuoEyeSlash,
  "duo-trash-undo": DuoTrashUndo,
  "duo-arrow-down": DuoArrowDown,
  "duo-spinner": DuoSpinner,
  "duo-paper-plane": DuoPaperPlane,
  "duo-x-mark": DuoXMark,
  "duo-badge-check": DuoBadgeCheck,
  "solid-angle-up": SolidAngleUp,
  "solid-angle-down": SolidAngleDown,
  "duo-ellipsis-vertical": DuoEllipsisVertical,
  "duo-check-double": DuoCheckDouble,
  "duo-file-pdf": DuoFilePdf,
  "duo-heart": DuoHeart,
  "regular-heart": RegularHeart,
  "duo-square-arrow-left": DuoSquareArrowLeft,
  "duo-check": DuoCheck,
  "duo-folder-closed": DuoFolderClosed,
  "duo-triangle-exclamation": DuoTriangleExclamation,
  "duo-life-ring": DuoLifeRing,
  "duo-bars": DuoBars,
  "duo-circle-user": DuoCircleUser,
  "duo-circle-check": DuoCircleCheck,
  "duo-chevrons-up": ChevronsUp,
  "duo-box-archive": BoxArchive,
  "duo-pen": Pen
};

export const size = {
  "2xs": 10,
  "xs": 12,
  "sm": 14,
  "lg": 20,
  "xl": 24,
  "2xl": 32,
  "1x": 16,
  "2x": 32,
  "3x": 48,
  "4x": 64,
  "5x": 80,
  "6x": 96,
  "7x": 112,
  "8x": 128,
  "9x": 144,
  "10x": 160,
};
