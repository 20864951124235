import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import PricingSection from "../../components/content-and-information-pages/pricing-section/view/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection />
    </>
  );
}

export default PricingPage;
