import React from "react";

import { useAppContext } from "../../../../context/AppContext";
import classNames from "../../../../util/classNames";

const WhiteBackgroundLayout = ({ children }) => {
  const { isDarkTheme } = useAppContext();

  return (
    <section className="py-12">
      <div
        className={classNames(
          isDarkTheme ? `bg-[#333333]` : `bg-white`,
          "container mx-auto max-w-6xl rounded p-2.5 sm:p-6"
        )}
      >
        {children}
      </div>
    </section>
  );
};

export default WhiteBackgroundLayout;
