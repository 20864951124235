export const GET_ALL_SUBJECTS = "GET_ALL_SUBJECTS";
export const GOT_ALL_SUBJECTS = "GOT_ALL_SUBJECTS";

export const GET_RECENT_SEARCH = "GET_RECENT_SEARCH";
export const GOT_RECENT_SEARCH = "GOT_RECENT_SEARCH";

export const FLOATING_SEARCH_BOX_SHOWN = "IS_FLOATING_SEARCH_BOX_SHOWN";
export const FLOATING_SEARCH_BOX_HIDDEN = "IS_FLOATING_SEARCH_BOX_HIDDEN";

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const GOT_USER_PERMISSIONS = "GOT_USER_PERMISSIONS";

export const GET_ALL_QUIZ_SUBJECTS = "GET_ALL_QUIZ_SUBJECTS";
export const GOT_ALL_QUIZ_SUBJECTS = "GOT_ALL_QUIZ_SUBJECTS";