import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "./../../util/auth";

const DashboardSection = lazy(() => import('../../components/miscellaneous/dashboard/view/DashboardSection'));

function DashboardPage(props) {

  return (
    <>
      <Meta title="Dashboard" />
      <Suspense fallback={<div>Loading...</div>}>
        <DashboardSection />
      </Suspense>
    </>
  );
}

export default requireAuth(DashboardPage);
