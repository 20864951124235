import { TOST_MESSAGE_BOX_HIDDEN, TOST_ERROR_MESSAGE, GET_TOST_MESSAGE_TEXT } from "./actionTypes";

export const tostMessageBoxShown = (isTostMessageBoxShown) => {
  return {
    type: TOST_MESSAGE_BOX_HIDDEN,
    payload: isTostMessageBoxShown,
  };
};

export const tostMessageType = (tostMessageType) => {
  return {
    type: TOST_ERROR_MESSAGE,
    payload: tostMessageType,
  };
};

export const tostMessageText = (tostMessageText) => {
  return {
    type: GET_TOST_MESSAGE_TEXT,
    payload: tostMessageText,
  };
};
