import { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import ReactDOMServer from "react-dom/server";

const useMarkDownPreview = () => {
  
  const CodeRenderer=({value})=> {
    return (
      <pre>
        <code>{value}</code>
      </pre>
    );
  }

  const customRendererOptions = useMemo(() => {
    return {
      previewRender(text) {
        return ReactDOMServer.renderToString(
          <ReactMarkdown
            children={text}
            renderers={{
              CodeBlock: CodeRenderer,
              Code: CodeRenderer,
            }}
          />
        );
      },
    };
  }, []);

  return { customRendererOptions };
};

export default useMarkDownPreview;
