import React from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import LegalSection from "../../components/content-and-information-pages/legal/view/LegalSection";
import { useRouter } from "./../../util/router";

function LegalPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Legal" />
      <LegalSection section={router.query.section} key={router.query.section} />
    </>
  );
}

export default LegalPage;
