const profileSubMenu = (auth, handelLoginInfo, handelClearAllStore, haveBasicPlan, haveAdvancePlan) => {

  const handelSingOut = async (e) => {
    e.preventDefault();
    handelLoginInfo("logout", false);
    await handelClearAllStore();
    auth.signout();
  };

  const menuList = [
    { name: "Settings", to: `/settings/general`},
    { name: "Archived List", to: `/archived-list`},
    { name: "Deleted List", to: haveAdvancePlan ? `/deleted-list`: '/pricing' },
    { name: "Deleted Quiz List", to: haveBasicPlan ? `/deleted-quiz-list`: '/pricing' },
    { name: "Clear Cache", to: `/clear-cache` },
    {
      name: "Sign Out",
      to: "/auth/signin",
      onClick: handelSingOut,
    },
  ];

  return menuList;
};

export default profileSubMenu;
