import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const UsersListSection = lazy(() => import('../../components/authentication-and-user-management/user-list-section/view/UsersListSection'));

function UsersListPage(props) {

  return (
    <>
      <Meta title="Users" />
      <Suspense fallback={<div>Loading...</div>}>
        <UsersListSection />
      </Suspense>
    </>
  );
}

export default requireAuth(UsersListPage);
