import { firebaseApp } from "./firebase";
import { getAuth } from "firebase/auth";
import getIpAddress from "./getIpAddress";
const baseUrl = process.env.REACT_APP_ENDPOINT_BASE_URL;

let ip = window.networkIp;


(async () => {
  if (!ip) {
    ip = await getIpAddress();
    window.networkIp = ip;
  }
})();


export const requestApi = async (endPoint, method, data) => {
  const auth = getAuth(firebaseApp);

  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

console.warn(accessToken);


  return fetch(`${baseUrl}${endPoint}`, {
    method: method,
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": `${ip}`,
      Authorization: `Bearer ${accessToken}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: data ? JSON.stringify({ ...data }) : undefined,
  })
    .then(async (response) => {
      try {
        let res = await response.text();
        if (res) {
          return JSON.parse(res);
        }
      } catch (err) {
        return err;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const requestPdfApi = async (endPoint, method, data) => {
  const auth = getAuth(firebaseApp);


  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  return fetch(`${baseUrl}${endPoint}`, {
    method: method,
    mode: "cors",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": `${ip}`,
      Authorization: `Bearer ${accessToken}`,
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ ...data }),
  })
    .then(async (response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .catch((error) => {
      console.log(error);
    });
};
