import { useEffect, useState } from "react";

import { getAllSubJectQuestions } from "../../util/db";

const useAllSubjectQuestions = (uid) => {
  const [allSubjectQsn, setAllSubject] = useState([]);

  useEffect(() => {
    if (!uid) return;

    getAllSubJectQuestions()
      .then((response) => {
        if (response.status === "success") {
          setAllSubject(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }, [uid]);

  return { allSubjectQsn };
};

export default useAllSubjectQuestions;
