import { useCallback } from "react";
import decodeJWT from "../../util/decodeJWT";
import { useAuth } from "../../util/auth";

const useAuthorize = () => {
  const auth = useAuth();

  const isAuthorize = useCallback((permissionList = []) => {
    if (!auth.user) return false;
    const permissions = decodeJWT(auth.user.accessToken);
    return permissionList.some((element) => permissions[element] === true);

  }, [auth]);

  return { isAuthorize };
};

export default useAuthorize;
