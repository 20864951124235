import { GET_ALL_SUBJECTS, GET_RECENT_SEARCH, FLOATING_SEARCH_BOX_HIDDEN, GET_USER_PERMISSIONS, GET_ALL_QUIZ_SUBJECTS } from "./actionTypes";

export const getAllSubjects = (data) => {
  return {
    type: GET_ALL_SUBJECTS,
    payload: data
  };
};

export const getAllQuizSubjects = (data) => {
  return {
    type: GET_ALL_QUIZ_SUBJECTS,
    payload: data
  };
};

export const getRecentSearch = (data) => {
  return {
    type: GET_RECENT_SEARCH,
    payload: data
  };
};

export const floatingSearchBoxShown = (isFloatingSearchBoxShown) => {
  return {
    type: FLOATING_SEARCH_BOX_HIDDEN,
    payload: isFloatingSearchBoxShown
  };
};

export const getUserPermissions = (permissions) => {
  return {
    type: GET_USER_PERMISSIONS,
    payload: permissions
  };
};