import React from "react";

import SvgIcon from "../../../../Icons/SvgIcon";
import { useAppContext } from "../../../../context/AppContext";

const NoResultFound = () => {
  const { isDarkTheme } = useAppContext();

  return (
    <div className="py-14 px-6 text-center text-sm sm:px-14">
      {/* <ExclamationTriangleIcon
        className="mx-auto h-6 w-6 text-gray-400"
        aria-hidden="true"
      /> */}
      <SvgIcon iconName="duo-triangle-exclamation" sizeName="xl" className="mx-auto" />
      <p className={`mt-4 font-semibold ${isDarkTheme ? `text-white` : `text-gray-900`}`}>No results found</p>
      <p className={`mt-2 ${isDarkTheme ? `text-slate-300` : `text-gray-500`}`}>
        We couldn’t find anything with that term. Please try again.
      </p>
    </div>
  );
};

export default NoResultFound;
