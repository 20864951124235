
export const storeInLocal = (value) =>{
    let selectedItemsFromSearch=[];

    selectedItemsFromSearch = JSON.parse(
      localStorage.getItem("selectedItemsFromSearch")
    );

    if (!selectedItemsFromSearch) {

      selectedItemsFromSearch = [value];

    } else if (selectedItemsFromSearch.length) {

      selectedItemsFromSearch = selectedItemsFromSearch.filter((x) => {
          return x.name !== value.name
      })

      if (selectedItemsFromSearch.length > 2) {
        selectedItemsFromSearch = selectedItemsFromSearch.slice(
          Math.max(selectedItemsFromSearch.length - 2, 1)
        );
      }
      selectedItemsFromSearch.push(value)
    } 

    localStorage.setItem('selectedItemsFromSearch', JSON.stringify(selectedItemsFromSearch));
  
}