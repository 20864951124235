import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import { Link } from "../../../../util/router";
import classNames from "../../../../util/classNames";
import { useAppContext } from "../../../../context/AppContext";

const DropDownMenu = ({menuName, menuList=[], activeClass, isActionLoading=false, mlClass="ml-5"}) => {
  const { isDarkTheme } = useAppContext();

    const handelSubMenuClick=(event)=>{
        event.stopPropagation();
    };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button as="div" onClick={handelSubMenuClick} className={`${mlClass} font-semibold cursor-pointer ${activeClass}`}>{menuName}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${isDarkTheme ? `bg-[#333333]` : `bg-white`}`}>
          <div className="py-1">
          {
            menuList.map((eachMenu)=>(
                <Menu.Item key={eachMenu.name}>
                {({ active }) => (
                  <Link
                    to={eachMenu.to}
                    className={classNames(
                      isDarkTheme ? (active ? "bg-[#515354] text-white" : "") : (active ? "bg-gray-100 text-gray-900" : ""),
                      isActionLoading? ("text-gray-200 pointer-events-none cursor-default") : (isDarkTheme ? "" : "text-gray-700"),
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={eachMenu.onClick}
                  >
                    {eachMenu.name}
                  </Link>
                )}
              </Menu.Item>
            ))
          }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDownMenu;
