import React from "react";

import IconContainer from "../icon-container/IconContainer";
import SvgIcon from "../../../../Icons/SvgIcon";
import { useAppContext } from "../../../../context/AppContext";

const Layout = ({ children, sectionName, haveAPreviousPage = false, onClick, id, isDarkWithToggleNeed = true }) => {
    const { isDarkTheme } = useAppContext();
    const themeColor = isDarkWithToggleNeed ? isDarkTheme ? `bg-[#333333]` : `bg-white`:"";

    return (
        <section className="py-10 px-4 " id={id}>
            <div className="container mx-auto max-w-6xl">
                <div className="flex">
                    {haveAPreviousPage ? (
                        <div className="absolute">
                            <IconContainer onClick={onClick}>
                                <SvgIcon iconName="duo-arrow-left" sizeName="xl" fill={`${isDarkTheme ? `white` : ``}`} />
                            </IconContainer>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="mb-14 flex justify-center flex-1">
                        <h1 className="mb-6 text-3xl font-medium text-center">
                            {sectionName}
                        </h1>
                    </div>
                </div>
                <div className={`${themeColor} rounded mx-3`}>
                    {children}
                </div>
            </div>
        </section>
    );
};

export default Layout;
