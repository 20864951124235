import { useCallback } from "react";
import { useRouter } from "../../util/router";
import useScreenUses from "../utilities-and-helpers/useScreenUses";

const useActiveNav = () => {
  const { pathname } = useRouter();
  const { handelClearInterval } = useScreenUses();

  const getActiveClass = useCallback(
    (routerName, isMobile = false) => {
      const textColorName =  "text-indigo-600";
      const parentPathname = pathname.split("/");
      let routerNameWithoutSlash = routerName.replaceAll("/", "");
      if (parentPathname.includes(routerNameWithoutSlash)) {
        return textColorName;
      }
      if (parentPathname.join("") === routerNameWithoutSlash && isMobile) {
        return textColorName;
      }
      handelClearInterval(pathname);
    },
    // eslint-disable-next-line
    [pathname]
  );
  return { getActiveClass };
};

export default useActiveNav;
