import { useCallback } from "react";
import { useDispatch } from "react-redux";
import * as actionTypeTostMessage from "../../components/miscellaneous/tost-message/redux/action";

const useTostMessage = () => {
  const dispatch = useDispatch();

  const dispatchMySaga = useCallback(
    (payload) => {
      dispatch(actionTypeTostMessage.tostMessageBoxShown(payload.isOpen));
      dispatch(actionTypeTostMessage.tostMessageType(payload.type));
      dispatch(actionTypeTostMessage.tostMessageText(payload.message));
    },
    [dispatch]
  );

  const handelTostMessage = useCallback(
    (isOpen = false, type = "", message = "") => {
      if (type && message) {
        dispatchMySaga({ isOpen, type, message });
      }
    },
    [dispatchMySaga]
  );

  return { handelTostMessage };
};
export default useTostMessage;
