import { useCallback, useEffect } from 'react';
import { Switch } from '@headlessui/react'

import { useAppContext } from '../../../../context/AppContext';
import classNames from '../../../../util/classNames';
import { useAuth } from '../../../../util/auth';

const ToggleTheme = () => {
  const { isDarkTheme, setIsDarkTheme } = useAppContext();
  const auth = useAuth();

  const handelSaveDarkMode = useCallback((isDarkMode)=>{
    if(!auth.user) return;
    auth.updateProfile({ isDarkMode:isDarkMode })
    .then(() => {
      console.log("isDarkMode", isDarkMode); // TODO: Show message
    })
  },[auth]);

  useEffect(()=>{
    if(!auth.user) return;
      setIsDarkTheme(auth.user.isDarkMode);
      // eslint-disable-next-line
  },[auth])

  return (
    <Switch
      checked={isDarkTheme}
      onChange={(checked) => {
        setIsDarkTheme(checked);
        handelSaveDarkMode(checked);
      }}
      className={classNames(
        isDarkTheme ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          isDarkTheme ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
};

export default ToggleTheme;