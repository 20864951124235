import { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext({ isMobile: false, isDarkTheme: false });

export default function AppContextProvider({ children }) {
  const [isMobile, setIsMobile] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    const mobileQuery = window.matchMedia("(max-width: 640px)");

    function handleMobileChange(mobileQuery) {
      if (mobileQuery.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    handleMobileChange(mobileQuery);

    mobileQuery.addEventListener("change", handleMobileChange);

    return () => mobileQuery.removeEventListener("change", handleMobileChange);
  }, []);

  useEffect(() => {
    if (isDarkTheme) {
      document.body.className = "dark";
    } else {
      document.body.className = "light";
    }
  }, [isDarkTheme]);

  return (
    <AppContext.Provider value={{ isMobile, isDarkTheme, setIsDarkTheme }}>{children}</AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
