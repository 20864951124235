import { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import { Dialog, Transition } from "@headlessui/react";

import { Link } from "../../../../util/router";
import useActiveNav from "../../../../hooks/authentication-and-user-information/useActiveNav";
import { useAuth } from "../../../../util/auth";
import useAuthorize from "../../../../hooks/authentication-and-user-information/useAuthorize";
import DropDownSubMenu from "./DropDownSubMenu";
import { useAppContext } from "../../../../context/AppContext";
import SvgIcon from "../../../../Icons/SvgIcon";
import ToggleTheme from "../../toggle-theme/view/ToggleTheme";

const SideSheetNav = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { getActiveClass } = useActiveNav();
  const { isAuthorize } = useAuthorize();
  const { isDarkTheme } = useAppContext();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const haveAdvancePlan = isAuthorize(["isAdvancePlan"]);
  const havePermissionForSupperAdmin = isAuthorize(["isSuperAdmin"]);

  const handelCloseAfterClick = () => {
    setSidebarOpen(false);
  };

  const handelClick = () => {
    setSidebarOpen(false);
    navigate("/");
  }

  return (
    <div>
      <button
        className={`inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 ${isDarkTheme ? `text-white hover:bg-[#515354]` : `text-gray-600 hover:bg-gray-100 hover:text-gray-500`}`}
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open main menu</span>
        <SvgIcon iconName="duo-bars" sizeName="xl" />
      </button>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={handelCloseAfterClick}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className={`relative flex w-full max-w-xs flex-1 flex-col pt-5 pb-4 ${isDarkTheme ? `bg-[#333333]` : `bg-white`}`}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className={`ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ${isDarkTheme ? `hover:bg-[#6c6c6c]` : `hover:bg-[#a2a9a9]`}`}
                      onClick={handelCloseAfterClick}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <SvgIcon iconName="duo-x-mark" sizeName="xl" className="fill-white" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="pb-3 flex flex-shrink-0 items-center justify-between px-4 border-b">
                  <div className="cursor-pointer" onClick={handelClick}>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      alt="Your Company"
                    />
                  </div>
                  <ToggleTheme />
                </div>
                <div className="mt-2 h-0 flex-1 overflow-y-auto">
                  <nav className="space-y-1 px-2 flex items-start flex-col">
                    {(!auth.user || !auth.user.stripeSubscriptionId) && (
                      <Link
                        to="/pricing"
                        className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass(
                          "/pricing"
                        )}`}
                        onClick={handelCloseAfterClick}
                      >
                        Pricing
                      </Link>
                    )}
                    {auth.user && (
                      <>
                          <Link
                            to="/subjects"
                            className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass(
                              "/subjects"
                            )}`}
                            onClick={handelCloseAfterClick}
                          >
                            Subjects
                          </Link>

                        <Link to="/quiz-subjects" className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass("/quiz-subjects")}`} onClick={handelCloseAfterClick}>
                          Quiz Subjects
                        </Link>

                          <Link
                            to="/dashboard"
                            className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass(
                              "/dashboard"
                            )}`}
                            onClick={handelCloseAfterClick}
                          >
                            Dashboard
                          </Link>

                        <Link to="/quizzes-list" className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass("/quizzes-list")}`} onClick={handelCloseAfterClick}>
                          Quizzes List
                        </Link>

                        <Link to="/add-quiz" className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass("/add-quiz")}`} onClick={handelCloseAfterClick}>
                          Add Quiz
                        </Link>

                        <Link to="/quiz-settings" className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass("/quiz-settings")}`} onClick={handelCloseAfterClick}>
                          Quiz Setting
                        </Link>

                        {!havePermissionForSupperAdmin &&
                          haveAdvancePlan && (
                            <Link
                              to="/add-question"
                              className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass(
                                "/add-question"
                              )}`}
                              onClick={handelCloseAfterClick}
                            >
                              Add Question
                            </Link>
                          )}
                          
                        {havePermissionForSupperAdmin && (
                          <DropDownSubMenu
                            handelCloseAfterClick={handelCloseAfterClick}
                          />
                        )}
                      </>
                    )}
                    {!auth.user && (
                      <Link
                        to="/auth/signin"
                        className={`py-2 px-3 w-full text-xl font-semibold ${getActiveClass(
                          "/signin"
                        )}`}
                        onClick={handelCloseAfterClick}
                      >
                        Sign In
                      </Link>
                    )}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default SideSheetNav;
