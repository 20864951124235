import React, { Suspense, lazy } from "react";

import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { useRouter } from "../../util/router";

const AuthSection = lazy(() => import('../../components/authentication-and-user-management/auth/view/AuthSection'));

function AuthPage(props) {
  const router = useRouter();
  const { type } = props;

  return (
    <>
      <Meta title="Auth" />
      <Suspense fallback={<div>Loading...</div>}>
        <AuthSection
          type={ type ? type : router.query.type }
          providers={["google", "facebook", "twitter"]}
          afterAuthPath={router.query.next || "/quiz-subjects"}
        />
      </Suspense>
    </>
  );
}

export default AuthPage;
