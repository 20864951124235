import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { useRouter } from "./../../util/router";
import { requireAuth } from "./../../util/auth";

const SettingsSection = lazy(() => import('../../components/miscellaneous/settings/view/SettingsSection'));

function SettingsPage(props) {
  const router = useRouter();

  return (
    <>
      <Meta title="Settings" />
      <Suspense fallback={<div>Loading...</div>}>
        <SettingsSection
          section={router.query.section}
          key={router.query.section}
        />
      </Suspense>
    </>
  );
}

export default requireAuth(SettingsPage);
