import SvgIcon from "../../../../Icons/SvgIcon";

const AdminMenuName = ({ isOpen }) => {

  return (
    <div className="flex items-center">
      <span className="sm:hidden py-2 text-xl font-semibold">Admin</span>
      <span className={`${isOpen === undefined ? "" : "hidden"}`}>Admin</span>

      {isOpen ? (
        <SvgIcon iconName="solid-angle-up" sizeName="lg" className="mt-2 sm:mt-1 ml-1" />
      ) : (
        <SvgIcon iconName="solid-angle-down" sizeName="lg" className="mt-2 sm:mt-1 ml-1" />
      )}
    </div>
  );
};

export default AdminMenuName;
