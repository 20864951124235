import React, { memo } from "react";
import SimpleMdeReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

import "./MdEditor.css"
import useMarkDownPreview from "../../../../hooks/utilities-and-helpers/useMarkDownPreview";
import { useAppContext } from "../../../../context/AppContext";

const MdEditor = memo(({ value, onChange }) => {
  const { customRendererOptions } = useMarkDownPreview();
  const { isDarkTheme } = useAppContext();

  return (
    <SimpleMdeReact
      value={value}
      onChange={onChange}
      options={customRendererOptions}
      className={`${isDarkTheme ? `react-simplemde-editor-dark ` : ``}`}
    />
  );
});

export default MdEditor;
