import { useCallback } from "react";
import { setLogInInfo } from "../../util/db";
import useDeviceUId from "../../util/deviceUId";

export const useLogInInfo = () => {
  const { deviceInfo } = useDeviceUId();

  const handelLoginInfo = useCallback(
    (type, isLoggedInStatus) => {
      if (type && Object.keys(deviceInfo).length) {
        let deviceInfoAndLogStatus = {
          ...deviceInfo,
          isLoggedIn: isLoggedInStatus,
        };
        const response = setLogInInfo({
          type: type,
          deviceInfo: deviceInfoAndLogStatus,
        })
          .then((response) => {
            if (response.message === "Already logged in another device") {
              return response.isLoggedIn;
            }
          })
          .catch((error) => {
            console.log(error);
            return false;
          });

        return response;
      }
    },
    [deviceInfo]
  );


  return { handelLoginInfo };
};
