import React, { Suspense, lazy } from "react";
import Meta from "../../components/meta-and-theme/meta/view/Meta";
import { requireAuth } from "../../util/auth";

const SubjectsSection = lazy(() => import('../../components/quiz-management/subjects-section/view/SubjectsSection'));

function SubjectsPage(props) {
  return (
    <>
      <Meta title="Question answers subjects" />
      <Suspense fallback={<div>Loading...</div>}>
        <SubjectsSection />
      </Suspense>
    </>
  );
}

export default requireAuth(SubjectsPage);