import { useAppContext } from "../../../../context/AppContext";

const IconContainer = ({ children, onClick, className, bgColor = "", title }) => {
  const { isDarkTheme } = useAppContext();

  return (
    <div title={title} className="flex justify-center items-center ">
      <div
        className={(isDarkTheme ? `hover:bg-[#515354]` : `hover:bg-gray-200`) + ` p-2 flex items-center justify-center cursor-pointer rounded-full ${className} ${bgColor}`}
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  );
};

export default IconContainer;
